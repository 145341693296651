import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Link, graphql, useStaticQuery } from 'gatsby';
import sortBy from 'lodash/sortBy';

import Breadcrumbs from '../Breadcrumbs';
import {
  mdxComponents,
  rightMenuListId,
  textContainerId,
  previousButton,
  nextButton,
} from '../../constants';
import {
  createContentMenuList,
  scrollToSection,
  setNavigationButtons,
  createMenuList,
} from '../../utils';

const draftPages = [];

const removeDraftPages = (node) => {
  return !draftPages.includes(node.frontmatter.slug);
}

const Markdown = ({ navigate, location: { pathname, href }, crumbs, path }) => {
  const documentRef = useRef();
  const menuRef = useRef();

  useEffect(() => {
    createContentMenuList({
      content: documentRef.current,
      menuId: rightMenuListId,
      fullBody: true,
    });

    setNavigationButtons({
      menuList: createMenuList(menuRef.current.querySelectorAll('a')),
      page: pathname,
      nextButton,
      previousButton,
      callback: navigate,
    });

    const headerRef = href.split('#')[1];
    if (headerRef) {
      scrollToSection(headerRef);
    }
  }, [href, pathname, navigate]);

  const {
    allMdx: { nodes: mdxNodes },
  } = useStaticQuery(graphql`
    query MyQuery {
      allMdx {
        nodes {
          body
          frontmatter {
            slug
            title
            order
          }
        }
      }
    }
  `);
  const nodes = sortBy(
    mdxNodes.filter(({ frontmatter }) => !!frontmatter.slug),
    ({ frontmatter }) => frontmatter.order || -1,
  );
  const node = nodes.filter(({ frontmatter }) => frontmatter.slug === pathname)[0];


  return (
    <React.Fragment>
      <div>
        <div id="markdown-page-container">
          <Breadcrumbs
            crumbs={[...crumbs, { name: node.frontmatter.title, href: node.frontmatter.slug }]}
          />
          <nav className="card left-side-menu">
            <ul ref={menuRef}>
              {nodes.filter(removeDraftPages).map(
                ({ frontmatter }) =>
                  frontmatter.slug.includes(path) && (
                    <li className="menu-list-item" key={frontmatter.slug}>
                      <Link
                        className={`${frontmatter.slug === pathname ? ' active' : ''}`}
                        to={frontmatter.slug}
                      >
                        {frontmatter.title}
                      </Link>
                    </li>
                  ),
              )}
            </ul>
          </nav>
          <div>
            <div ref={documentRef} id={textContainerId} className="text-container card">
              <MDXProvider components={mdxComponents}>
                <MDXRenderer>{node.body}</MDXRenderer>
              </MDXProvider>
            </div>
            <div className="button-container">
              <button id={previousButton} type="button" className="button previous-button">
                &nbsp
              </button>
              <button id={nextButton} type="button" className="button next-button">
                &nbsp
              </button>
            </div>
          </div>
          <div className="card right-side-menu">
            <h3>Content</h3>
            <ul id={rightMenuListId} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Markdown.propTypes = {
  crumbs: PropTypes.array,
  path: PropTypes.string,
};

export default Markdown;
