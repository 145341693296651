import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import BreadcrumbArrow from '../../images/breadcrumb_arrow.svg';

const Breadcrumbs = ({ crumbs }) => (
  <ul className="breadcrumbs-container">
    {crumbs.map((crumb, idx) => (
      <li key={crumb.href}>
        {idx > 0 && <img src={BreadcrumbArrow} alt="right arrow" />}
        <Link to={crumb.href}>{crumb.name}</Link>
      </li>
    ))}
    <li id="side-menu-crumb"></li>
  </ul>
);

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array,
};

Breadcrumbs.defaultProps = {
  crumbs: [{ name: 'Home', href: '/' }],
};

export default Breadcrumbs;
