import CodeBlock from "./components/CodeBlock";

export const nextButton = "next-button";
export const previousButton = "previous-button";
export const leftMenuListId = "left-side-menu";
export const rightMenuListId = "right-side-menu-list";
export const mainContentId = "content-wrapper";
export const textContainerId = "text-container";
export const cardHeading = "card-heading";
export const contentMenuHeadings = ["h1", "h2", "h3"].join(",");

export const mdxComponents = {
  code: CodeBlock,
};

export const headerMenu = [
  { path: "/api", text: "API" },
];
