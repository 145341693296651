/* eslint-disable react/no-array-index-key */
import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from './theme';

const CodeBlock = (props) => {
  const language = props.className ? props.className.replace(/language-/, '') : 'javascript';
  return (
    <Highlight {...defaultProps} theme={theme} code={props.children.trim()} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style, overflow: 'auto', padding: '20px 0' }}>
          {tokens.map((line, i) => (
            <div
              key={i}
              {...getLineProps({ line, key: i })}
              style={{ position: 'relative', paddingLeft: '46px' }}
            >
              <div className="line-number">{i}</div>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

export default CodeBlock;
