import memoize from 'lodash/memoize';

import BreadcrumbArrow from './images/breadcrumb_arrow.svg';
import { cardHeading, contentMenuHeadings } from './constants';

export const getSectionName = (section = '') => {
  return section ? `${section.replace(/[\s',!]/g, '').toLowerCase()}-section` : '';
};

export const getPageName = (section) => section && `${section.replace(/\s/g, '-').toLowerCase()}`;

export const scrollToSection = (sectionId) => {
  const section = `${sectionId}${sectionId.includes('-section') ? '' : '-section'}`;
  const sectionEl = document.getElementById(section);

  if (!sectionEl) {
    return;
  }

  sectionEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const domElToArray = (arr) => [].slice.call(arr);

export const capitalise = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const createMenuList = (arr) =>
  domElToArray(arr).map((el) => ({ text: el.innerText, link: el.pathname }));

export const addBreadcrumbs = (breadcrumb) => {
  const crumb = document.getElementById('side-menu-crumb');
  const li = document.createElement('li');
  const a = document.createElement('a');
  const img = document.createElement('img');
  a.href = breadcrumb.href;
  a.innerText = breadcrumb.name;
  img.src = BreadcrumbArrow;
  li.id = 'side-menu-crumb';
  li.append(img);
  li.append(a);
  crumb.replaceWith(li);
};

const updateList = (headers, listRef) => {
  if (headers.length) {
    /* eslint-disable no-param-reassign */
    listRef.innerHTML = '';
  }

  headers.forEach((header) => {
    const section = header.innerText;
    const sectionId = getSectionName(section);
    const ListItem = document.createElement('li');
    /* eslint-disable no-param-reassign */
    header.id = sectionId;

    ListItem.id = `list_${sectionId}`;
    ListItem.onclick = () => scrollToSection(sectionId.replace('list_'));
    ListItem.innerHTML = section;
    listRef.append(ListItem);
  });
};

export const createContentMenuList = memoize(({ content, menuId, headers, fullBody }) => {
  const listRef = document.getElementById(menuId);

  if (fullBody) {
    return updateList(queryElements(content, headers || contentMenuHeadings), listRef);
  }

  /**
   * filter out any headings with the 'card-heading' class because
   * we don't want to pick up headings that exist inside a card
   */
  const nodes = queryElements(content, contentMenuHeadings).filter(
    (node) => !domElToArray(node.classList).includes(cardHeading),
  );

  return updateList(nodes, listRef);
});

export const setNavigationButtons = ({ menuList, page, previousButton, nextButton, callback }) => {
  const nextButtonEl = document.getElementById(nextButton);
  const previousButtonEl = document.getElementById(previousButton);

  const setButton = ({ button, onclick, props }) => {
    /* eslint-disable no-param-reassign */
    if (!props) {
      button.style.display = 'none';
      return;
    }

    button.onclick = () => onclick(props.link);
    button.innerHTML = props.text;
  };

  menuList.forEach((item, idx) => {
    if (item.link === page) {
      const next = idx + 1;
      const back = idx - 1;

      nextButtonEl.style.display = 'block';
      previousButtonEl.style.display = 'block';

      setButton({ button: nextButtonEl, onclick: callback, props: menuList[next] });
      setButton({ button: previousButtonEl, onclick: callback, props: menuList[back] });
    }

    return null;
  });
};

export const queryElements = (element, query) => {
  return domElToArray(element.querySelectorAll(query));
};

export const getCookie = (name) => {
  if (typeof document !== 'undefined') {
    return document.cookie.split(/; */).reduce((prev, curr) => {
      try {
        const [key, value] = curr.split('=').map(decodeURIComponent);
        return { [key]: value, ...prev };
      } catch {
        return prev;
      }
    }, {})[name];
  }

  return '';
};
