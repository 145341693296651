import React from "react";
import { Link } from "gatsby";

import { headerMenu } from "../../constants";
import CanduLogo from "../../images/candu_logo.svg";

const Header = ({ page }) => (
  <header className="header">
    <Link to="/">
      <img width="260px" src={CanduLogo} alt="candu logo" />
    </Link>
    <ul>
      {headerMenu.map(({ path, text }) => (
        <li key={path} className={path === page ? "active-header" : ""}>
          <Link to={path}>{text}</Link>
        </li>
      ))}
      <li>
        <button
          className="button"
          type="button"
          onClick={() => window.location.replace("https://app.candu.ai/login")}
        >
          Login
        </button>
      </li>
    </ul>
  </header>
);

export default Header;
