import React from 'react';

import { crumbs } from './constants';

import { Markdown } from '../Layout';
import Header from '../Header';
import SEO from '../SEO';

const APIPage = (props) => (
  <React.Fragment>
    <SEO title="API" description="Everything you need to know about Candu's API." />
    <Header page="/api" />
    <Markdown {...props} crumbs={crumbs} path={props.path.replace('*', '')} />
  </React.Fragment>
);

export default APIPage;
