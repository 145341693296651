import React from "react";
import { Router, Redirect } from "@reach/router";
import { Helmet } from "react-helmet";

import Error404 from "../components/Error404";
import APIPage from "../components/APIPage";

import "../css/index.css";
import { getCookie } from "../utils";

const getUserId = () => getCookie("candu_cid");

if (typeof window !== "undefined") {
  window.analytics.identify(getUserId());
}

const Index = () => (
  <div>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/vnd5jzb.css" />
    </Helmet>
    <Router>
      <Redirect from="/" to="/api" noThrow />
      <Redirect from="/api" to="/api/javascript-api" noThrow />

      <Redirect from="/developer" to="/api" noThrow />
      <Redirect from="/home" to="/api" noThrow />
      <APIPage path="/api/*" />
      <Error404 path="*" />
    </Router>
  </div>
);

export default Index;
